import PropTypes from 'prop-types'
import '../StatusText/StatusText.css'
import useMediaQuery from '../../hooks/useMediaQuery'

const StatusText = ({ status, kind }) => {
    const isMobile = useMediaQuery('(max-width:1024px)')

    let statusValue
    const center = isMobile ? '' : 'text-center justify-center'
    switch (status) {
        case 'COMPLETED':
            statusValue = kind === 'payment' ? 'Ολοκληρώθηκε' : 'Ολοκληρωμένο'
            return (
                <div
                    className={`flex items-center self-start md:self-center ${center} gap-2`}
                >
                    <span className="dot bg-green"></span>
                    <span className="text-green font-bold">{statusValue}</span>
                </div>
            )
        case 'RECEIVED':
            statusValue =
                kind === 'payment'
                    ? 'Χρειάζεται επιβεβαίωση απο τον Δήμο'
                    : 'Απεσταλμένο'
            return (
                <div
                    className={`flex items-center self-start md:self-center ${center} gap-2`}
                >
                    <span className="dot bg-orange"></span>
                    <span className="text-orange font-bold">{statusValue}</span>
                </div>
            )
        case 'IN_REVIEW':
            statusValue =
                kind === 'payment' ? 'Αναμονή Επιβεβαίωσης' : 'Σε επεξεργασία'
            return (
                <div
                    className={`flex items-center self-start md:self-center ${center} gap-2`}
                >
                    <span className="dot bg-blue"></span>
                    <span className="text-blue font-bold">{statusValue}</span>
                </div>
            )
        case 'DECLINED':
            statusValue =
                kind === 'payment'
                    ? 'Ακυρώθηκε ή έχει γίνει επιστροφή'
                    : 'Απορρίφθηκε'
            /* The same because we have not this on design just to have the check about the kind. If it will remain the same, we should remove the check about the kind there.*/
            return (
                <div
                    className={`flex items-center self-start md:self-center ${center} gap-2`}
                >
                    <span className="dot bg-red-500"></span>
                    <span className="text-red-500 font-bold">
                        {statusValue}
                    </span>
                </div>
            )
        default:
            return 'Δεν υπάρχει διαθέσιμη κατάσταση'
    }
}

StatusText.propTypes = {
    status: PropTypes.string,
    kind: PropTypes.oneOf(['payment', 'balance-request']),
}

export default StatusText
