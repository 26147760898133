import Modal from '../../components/Modal/Modal'
import StatusDropDown from '../../components/StatusDropDown/StatusDropDown'
import PopupMessage from '../../components/PopupMessage/PopupMessage'
import Loading from '../../components/Loading/Loading'
import ModalToggle from '../../components/ModalToggle/ModalToggle'

import { useContext, useRef, useEffect, useState } from 'react'
import { useParams, useNavigate, Link } from 'react-router-dom'
import { AuthContext } from '../../contexts/AuthContext'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { formatDate, govpayDateFormat } from '../../helpers/formatters'
import {
    getBalanceRequest,
    updateBalanceRequest,
} from '../../api/balance-requests'
import { deleteGovpayDebt } from '../../api/debts'

import notFound from '../../assets/images/admin404.png'
import plusIcon from '../../assets/images/plus_icon.svg'

const AdminBalanceRequestEdit = () => {
    useEffect(() => {
        document.title =
            'Επεξεργασία Αίτησης Οφειλής - Πλατφόρμα Ηλ. Πληρωμών Δήμου Βύρωνα'
    }, [])

    const statusRef = useRef(null)
    const navigate = useNavigate()
    const [popup, setPopup] = useState({ message: '', type: '', shown: false })

    const [toggles, setToggles] = useState([])
    const [debts, setDebts] = useState([])

    const { id: balanceRequestId } = useParams()
    const { token } = useContext(AuthContext)
    const options = {
        headers: {
            Authorization: `Bearer ${token.access_token}`,
        },
    }

    const queryClient = useQueryClient()

    const { isLoading, data } = useQuery({
        queryKey: ['adminBalanceRequest', balanceRequestId, options],
        queryFn: () => getBalanceRequest(balanceRequestId, options),
    })

    let hasDebtsValue = ''
    const [debtExistance, setDebtExistance] = useState(hasDebtsValue)

    useEffect(() => {
        if (data) {
            const hasDebts = data.data?.hasDebts
            const debts = data.data?.govpayDepts
            if (hasDebts === true && debts.length > 0) {
                hasDebtsValue = 'yes'
            } else if (hasDebts === false) {
                hasDebtsValue = 'no'
            }
            setDebtExistance(hasDebtsValue)
            setDebts(debts)
        }
    }, [data])

    const handleCreateToggle = () => {
        const id = Date.now().toString()
        const newToggle = (
            <ModalToggle
                key={id}
                onDelete={() => handleDeleteToggle(id)}
                type="add"
                id={id}
            />
        )
        setToggles([...toggles, newToggle])
    }

    const handleDeleteToggle = (id, type = 'add') => {
        if (type === 'add') {
            setToggles(prevToggles =>
                prevToggles.filter(toggle => toggle.key !== id)
            )
        } else {
            handleRequestSave(debts)
            mutationDelete.mutate({
                method: 'DELETE',
                id,
            })
        }
    }

    const handleDebtExistance = e => {
        const value = e.target.value
        setDebtExistance(value)
    }

    const handleRequestSave = () => {
        let updateBalanceRequestBody
        const toggleInputs = []
        if (debtExistance === 'yes') {
            if (toggles.length === 0 && debts.length === 0) {
                setPopup(values => ({
                    ...values,
                    shown: true,
                    message: (
                        <>
                            <p className="font-bold">
                                Η αίτηση υπολοίπου δεν ενημερώθηκε.
                            </p>
                            <p>
                                {`Δεν υπάρχουν προσθήκες οφειλών. Παρακαλώ
                                προσπαθήστε ξανά προσθέτωντας μία ή αλλάξτε την
                                επιλογή οφειλής στον δήμο σε 'ΟΧΙ'.`}
                            </p>
                        </>
                    ),
                    type: 'error',
                }))
                return
            }
            if (toggles.length < 1) {
                updateBalanceRequestBody = {
                    status: statusRef.current.props.value.value,
                    hasDebts: true,
                    debts: debts.map(existingDebt => ({
                        paymentCode: existingDebt.paymentCode,
                        description: existingDebt.description,
                        amount: existingDebt.amount,
                        amountString: existingDebt.amountString,
                        expirationDate: govpayDateFormat(
                            existingDebt.expirationDate
                        ),
                        rfCode: existingDebt.rfCode,
                    })),
                }
            } else {
                // If toggles is not empty, continue with the existing logic
                toggles.forEach(e => {
                    const toggleID = e.key
                    const paymentCode = document.getElementById(
                        `${toggleID}-paymentCode`
                    ).value
                    const description = document.getElementById(
                        `${toggleID}-description`
                    ).value
                    const amount = document.getElementById(
                        `${toggleID}-price`
                    ).value
                    const amountString = document.getElementById(
                        `${toggleID}-fullPrice`
                    ).value
                    const rfCode = document.getElementById(
                        `${toggleID}-rfCode`
                    ).value
                    const expirationDate = document.getElementById(
                        `${toggleID}-endDate`
                    ).value

                    if (
                        !expirationDate ||
                        !paymentCode ||
                        !description ||
                        !amount ||
                        !amountString
                    ) {
                        setPopup(values => ({
                            ...values,
                            shown: true,
                            message: (
                                <>
                                    <p>Η αίτηση υπολοίπου δεν ενημερώθηκε.</p>
                                    <p>
                                        Παρακαλώ ελέγξτε όλα τα πεδία στις
                                        οφειλές και προσπαθήστε ξανά
                                    </p>
                                </>
                            ),
                            type: 'error',
                        }))
                        return
                    }

                    const formattedDate = govpayDateFormat(expirationDate)
                    const inputObject = {
                        paymentCode,
                        description,
                        amount: parseFloat(amount),
                        amountString,
                        expirationDate: formattedDate,
                        rfCode,
                    }
                    toggleInputs.push(inputObject)
                })

                updateBalanceRequestBody = {
                    status: statusRef.current.props.value.value,
                    hasDebts: true,
                    debts: toggleInputs,
                }
            }
        } else {
            updateBalanceRequestBody = {
                status: statusRef.current.props.value.value,
                hasDebts: debtExistance === '' ? null : false,
                debts: [],
            }
        }

        mutation.mutate(updateBalanceRequestBody)
    }

    const mutation = useMutation({
        mutationFn: updatedBalanceRequest => {
            return updateBalanceRequest(balanceRequestId, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedBalanceRequest),
            })
        },
        onSuccess: () => {
            queryClient.invalidateQueries({
                queryKey: ['adminBalanceRequest'],
            })

            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <p>
                        Οι αλλαγές υποβλήθηκαν με επιτυχία! Μπορείτε να δείτε τη
                        νέα κατάσταση της αίτησης στον πίνακα με τις&nbsp;
                        <Link
                            className="font-bold"
                            to={'/admin/balance-request'}
                        >
                            &quot;Αιτήσεις Υπολοίπου&quot;
                        </Link>
                    </p>
                ),
                type: 'success',
            }))

            setTimeout(() => {
                navigate('/admin/balance-request')
            }, 3000)
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η αίτηση υπολοίπου δεν ενημερώθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    const mutationDelete = useMutation({
        mutationFn: deleteBody =>
            deleteGovpayDebt(deleteBody.id, {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(deleteBody),
            }),
        onSuccess: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: <p>Η Οφειλή διαγράφθηκε επιτυχώς!</p>,
                type: 'success',
            }))

            setTimeout(() => {
                location.reload()
            }, 3000)
        },
        onError: () => {
            setPopup(values => ({
                ...values,
                shown: true,
                message: (
                    <>
                        <p>Η οφειλή δεν διαγράφθηκε.</p>
                        <p>Παρακαλώ προσπαθήστε ξανά</p>
                    </>
                ),
                type: 'error',
            }))
        },
    })

    if (isLoading) {
        return <Loading />
    }

    const balanceRequestData = data.data

    if (!balanceRequestData) {
        return (
            <>
                <div>
                    <img
                        src={notFound}
                        className="pb-10"
                        alt="Page not found"
                    />
                </div>
            </>
        )
    }

    return (
        balanceRequestData && (
            <>
                {popup.shown && (
                    <PopupMessage
                        message={popup.message}
                        type={popup.type}
                        closePopUp={() =>
                            setPopup(values => ({ ...values, shown: false }))
                        }
                        showCloseButton={true}
                    />
                )}

                <div className="flex flex-col py-10 md:py-0">
                    <div className="flex flex-col pb-32">
                        <header className="text-3xl text-center py-12">
                            Επεξεργασία Αίτησης Υπολοίπου
                        </header>

                        <Modal classes="w-[80%] pl-8 pr-12 pb-8 pt-20 self-center md:w-1/2">
                            <div className="flex flex-col self-center justify-around">
                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        ΑΦΜ Οφειλέτη:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {balanceRequestData.user.profile.taxid}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ονοματεπώνυμο Οφειλέτη:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {`${balanceRequestData.user.profile.firstName} ${balanceRequestData.user.profile.lastName}`}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Αριθμός Ηλεκτρονικής Αίτησης Υπολοίπου:
                                    </div>
                                    <div className="w-full text-secondary lg:w-2/3">
                                        {balanceRequestData.title}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ημερομηνία Υποβολής Αίτησης:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatDate(
                                            balanceRequestData.createdAt
                                        )}
                                    </div>
                                </div>

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Ημερομηνία Ενημέρωσης Αίτησης:
                                    </div>

                                    <div className="w-full text-secondary lg:w-2/3">
                                        {formatDate(
                                            balanceRequestData.updatedAt
                                        )}
                                    </div>
                                </div>

                                <StatusDropDown
                                    status={balanceRequestData.status}
                                    kind="balance-request"
                                    reference={statusRef}
                                />

                                <div className="text-base text-primary mb-5 flex flex-col gap-6 mt-10 items-center w-full font-bold lg:flex-row lg:mb-14">
                                    <div className="w-full text-left lg:text-right lg:w-1/3">
                                        Υπάρχει Οφειλή προς τον Δήμο;
                                    </div>

                                    <div className="w-full flex text-secondary lg:w-2/3 gap-8">
                                        <div className="flex gap-2 flex-wrap items-baseline">
                                            <label htmlFor="yes">ΝΑΙ</label>
                                            <input
                                                type="radio"
                                                id="yes"
                                                name="debt"
                                                value="yes"
                                                onChange={handleDebtExistance}
                                                checked={
                                                    debtExistance === 'yes'
                                                }
                                            />
                                        </div>

                                        <div className="flex gap-2 flex-wrap items-baseline">
                                            <label htmlFor="no">ΟΧΙ</label>
                                            <input
                                                type="radio"
                                                id="no"
                                                name="debt"
                                                value="no"
                                                onChange={handleDebtExistance}
                                                checked={debtExistance === 'no'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        <div className="flex flex-col w-full max-w-[75%] self-center mt-8 items-center">
                            {debts.length > 0 && debtExistance === 'yes' && (
                                <>
                                    {debts.map((debt, index) => (
                                        <ModalToggle
                                            key={index}
                                            type="info"
                                            data={debt}
                                            onDelete={() =>
                                                handleDeleteToggle(
                                                    debt.id,
                                                    'info'
                                                )
                                            }
                                        />
                                    ))}
                                </>
                            )}
                            {debtExistance === 'yes' ? (
                                <>
                                    <hr />
                                    <h2 className="text-3xl text-center py-8">
                                        Προσθήκη οφειλών
                                    </h2>
                                    {toggles}
                                    <Modal classes="w-full max-w-2xl md:h-auto h-fit flex justify-between p-5 rounded-md items-center">
                                        <p className="font-bold">
                                            Προσθήκη οφειλής
                                        </p>
                                        <button onClick={handleCreateToggle}>
                                            <img
                                                src={plusIcon}
                                                alt="plusIcon"
                                            />
                                        </button>
                                    </Modal>
                                </>
                            ) : null}
                            <button
                                className="mt-16 bg-primary text-white border border-primary hover:bg-white hover:text-primary transition-all rounded-lg w-fit py-3.5 px-10 self-center md:self-end"
                                type="submit"
                                onClick={() => handleRequestSave(debts)}
                                disabled={mutation.isLoading}
                            >
                                Αποθήκευση
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    )
}

export default AdminBalanceRequestEdit
