import '../Sidebar/Sidebar.css'

import { Link } from 'react-router-dom'
import NavItem from '../NavItem/NavItem'

import logo from '../../assets/images/logo.png'
import adminLogo from '../../assets/images/admin-logo.svg'

import PropTypes from 'prop-types'
import useAuth from '../../hooks/useAuth'
import { useState, useContext } from 'react'
import PaymentAccordion from '../PaymentAccordion/PaymentAccordion'
import { AuthContext } from '../../contexts/AuthContext'
import useProfileCompleted from '../../hooks/useProfileCompleted'
import AsteriskCard from '../AsteriskCard/AsteriskCard'

const userClasses = `
    nav-item
    fw-medium 
    text-lg 
    text-primary 
    mb-7 
    hover:bg-accent 
    hover:text-white 
    py-3 
    px-6 
    rounded-lg 
    transition-all 
`

const adminClasses = `
    nav-item
    fw-medium 
    text-lg 
    text-white 
    mb-2 
    hover:bg-[#627480]
    hover:text-white 
    py-5 
    px-10 
    transition-all
`

const disabledClasses = `
fw-medium 
text-lg 
text-secondary 
mb-2 
py-5 
px-10 
transition-all
bg-grey
cursor-not-allowed
disabled-menu
`

const adminUsername = 'adminUsername'
// TODO Fetch username from API

const Sidebar = ({ forAdmin = false }) => {
    const { getEmailVerified } = useAuth()
    const emailVerifed = getEmailVerified()
    const [active, setActive] = useState(false)
    const { token } = useContext(AuthContext)

    const handleToggle = () => {
        setActive(!active)
    }

    const profileCompleted = useProfileCompleted(token)

    return forAdmin ? (
        <aside className="h-full bg-primary flex flex-col justify-start admin-sidebar py-4">
            <Link
                className="flex gap-5 my-10 items-center justify-center"
                to="/admin/profile"
            >
                <img width="65" height="65" src={adminLogo} alt="admin-logo" />
                <p className="text-white text-lg">{adminUsername}</p>
            </Link>
            <nav>
                <ul className="sidebar--nav-menu text-white ">
                    <NavItem
                        classes={adminClasses}
                        path="/admin/payment"
                        text="Πληρωμές Οφειλών"
                    />
                    {/* <NavItem
                        classes={adminClasses}
                        path="/admin/instant-payment"
                        text="Οφειλές InstantPayment"
                    /> */}
                    <NavItem
                        classes={adminClasses}
                        path="/admin/balance-request"
                        text="Αιτήσεις Υπολοίπου"
                    />
                    <NavItem
                        classes={adminClasses}
                        path="/admin/users"
                        text="Χρήστες"
                    />
                    <NavItem
                        classes={adminClasses}
                        path="/admin/info"
                        text="Govpay Connect"
                    />
                </ul>
            </nav>
        </aside>
    ) : (
        <aside className="h-full flex flex-col justify-start pr-14 w-1/4 ">
            <Link to="/">
                <img
                    className="my-4"
                    width="90"
                    height="90"
                    src={logo}
                    alt="logo"
                />
            </Link>

            <nav>
                <ul className="">
                    {emailVerifed && profileCompleted ? (
                        <>
                            <NavItem
                                classes={userClasses}
                                path="/balance-requests"
                                text="Αίτηση Υπολοίπου και ιστορικό αιτήσεων"
                            />
                            <NavItem
                                classes={userClasses}
                                path="/payments"
                                text="Ιστορικό Πληρωμών"
                            />
                            <PaymentAccordion
                                active={active}
                                handleToggle={handleToggle}
                            />
                            <NavItem
                                classes={userClasses}
                                path="/payment"
                                text="Άμεση Πληρωμή"
                            />
                            <NavItem
                                classes={userClasses}
                                path="/contact-info"
                                text="Επικοινωνία - Υποστήριξη"
                            />
                            <a
                                target="_blank"
                                href="https://www.dimosbyrona.gr/article.php?id=12831"
                                rel="noreferrer"
                                className={userClasses}
                            >
                                Αιτήματα ρύθμισης οφειλών
                            </a>
                        </>
                    ) : (
                        <>
                            <>
                                <NavItem
                                    classes={disabledClasses}
                                    path="/payment"
                                    text="Άμεση Πληρωμή"
                                />
                                <NavItem
                                    classes={disabledClasses}
                                    path="/balance-requests"
                                    text="Αίτηση Υπολοίπου"
                                />
                                <NavItem
                                    classes={disabledClasses}
                                    path="/payments"
                                    text="Ιστορικό Πληρωμών"
                                />
                                <NavItem
                                    classes={disabledClasses}
                                    path="/debts/confirmed"
                                    text="Βεβαιώσεις - Ρυθμίσεις"
                                />
                                <NavItem
                                    classes={userClasses}
                                    path="/contact-info"
                                    text="Επικοινωνία - Υποστήριξη"
                                />
                            </>
                        </>
                    )}
                </ul>
            </nav>
            {(!profileCompleted || !emailVerifed) && (
                <>
                    <AsteriskCard
                        htmlContent={`<div>Προκειμένου να έχετε πλήρη πρόσβαση στην πλατφόρμα, παρακαλούμε όπως συμπληρώσετε όλα τα επεξεργάσιμα πεδία του προφίλ <a class="font-bold" href="${'/profile'}"> εδώ </a></div>`}
                    />
                </>
            )}
        </aside>
    )
}

Sidebar.propTypes = {
    forAdmin: PropTypes.bool,
}

export default Sidebar
