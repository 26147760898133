import './HomeLoggedOut.css'

import Header from '../Header/Header'

import useMediaQuery from '../../hooks/useMediaQuery'

import TopPart from './TopPart'
import MobileTopPart from './MobileTopPart'
import AccordionItem from '../../components/AccordionToggle/AccordionToggle'
import { useState } from 'react'

const HomeLoggedOut = () => {
    const isMobile = useMediaQuery('(max-width:1024px)')
    const [active, setActive] = useState(null)

    const handleToggle = index => {
        if (active === index) {
            setActive(null)
        } else {
            setActive(index)
        }
    }

    const faqs = [
        {
            id: 1,
            accordionTitle: 'Ταμειακή υπηρεσία',
            accordionContent: (
                <div>
                    <p>Ταμειακή Υπηρεσία Τηλ.: 213 2008634, 635, 637, 638</p>
                    <p>E-mail: tameio@dimosbyrona.gr</p>
                    <p>
                        Ώρες Εξυπηρέτησης Κοινού: Δευτέρα έως Παρασκευή 08:00 -
                        13:30 (για συναλλαγές μόνο μέσω POS με χρεωστική ή
                        πιστωτική κάρτα){' '}
                    </p>
                    <p>
                        Το Τμήμα Ταμειακής Υπηρεσίας είναι υπεύθυνο για την
                        είσπραξη και την διαχείριση των εσόδων και των εξόδων
                        του Δήμου Βύρωνα.
                    </p>
                </div>
            ),
        },
        {
            id: 2,
            accordionTitle: 'Δημοτικό Κοιμητήριο',
            accordionContent: (
                <div>
                    <p>Τηλ: 210 7661188, 210 7645429</p>
                    <p>
                        Το Τμήμα Δημοτικού Κοιμητηρίου ασχολείται με τη
                        λειτουργία, τον καθαρισμό και τη συντήρηση του Δημοτικού
                        Κοιμητηρίου Βύρωνα. Επίσης, εισπράττει τα δικαιώματά του
                        από τους δημότες και χορηγεί βεβαιώσεις πληρωμών.
                    </p>
                </div>
            ),
        },
        {
            id: 3,
            accordionTitle: 'Έσοδα',
            accordionContent: (
                <div>
                    <p>Έσοδα Τηλ: 213 2008628, 629, 630</p>
                    <p>E-mail: esoda@dimosbyrona.gr</p>
                    <p>
                        Ώρες Εξυπηρέτησης Κοινού: Δευτέρα έως Παρασκευή: 08:00 -
                        13:00 (για πάσης φύσεως πληρωμές) Δευτέρα έως Παρασκευή:
                        08:00 - 14:00 (για πληροφορίες){' '}
                    </p>
                    <p>
                        {' '}
                        Το Τμήμα Εσόδων είναι υπεύθυνο για την έκδοση αδειών
                        άσκησης υπαιθρίων επαγγελμάτων και κατάληψης
                        κοινοχρήστων χώρων, καθώς και τον έλεγχο φορολογικών
                        στοιχείων και την επίλυση οικονομικών διαφορών. Στις
                        αρμοδιότητές του συγκαταλέγονται, επίσης, ο καθορισμός
                        του ύψους δικαιωμάτων, τελών και φόρων, η διαχείριση της
                        δημοτικής περιουσίας (μίσθωση, εκμίσθωση και εκποίηση
                        ακινήτων) και η είσπραξη των τελών ακίνητης περιουσίας
                        (ΤΑΠ).
                    </p>
                </div>
            ),
        },
    ]

    return (
        <>
            {isMobile ? null : (
                <Header
                    includeLogo={true}
                    withBorder={false}
                    includeButtons={true}
                />
            )}

            {isMobile ? <MobileTopPart /> : <TopPart />}

            <div className="w-full flex justify-center items-center bg-white p-12 flex-col">
                <div className="bg-[#580405] aqua-box p-8 text-center text-white font-bold max-w-5xl text-lg">
                    <p>
                        Η πλατφόρμα GovPay του Δήμου Βύρωνα είναι σε πιλοτική
                        λειτουργία. Οι πολίτες και οι φορείς μπορούν να
                        συνδεθούν μέσω taxisnet, για να προχωρήσουν στην πληρωμή
                        των οφειλών τους.
                    </p>
                </div>
                <p className="text-center text-2xl py-12 font-bold my-3">
                    Μπορείτε να πληρώσετε:
                </p>
                <div className="w-full max-w-3xl mb-8">
                    <ul className="list-disc text-xl flex flex-col gap-3">
                        <li>Δημοτικά Τέλη</li>
                        <li>Εγγραφές-Συνδρομές του Κολυμβητηρίου</li>
                        <li>{`Οφειλές του Κοιμητηρίου (π.χ. Φύλαξη Οστών, Οικογενειακούς Τάφους κλπ)`}</li>
                        <li>Τέλη χρήσης κοινόχρηστων χώρων</li>
                        <li>Μισθώματα</li>
                        <li>Παράβολα</li>
                        <li>Τέλη Παρεπιδημούντων</li>
                    </ul>
                </div>
                <div className="container mx-auto px-16 py-8 max-w-[60%]">
                    <h1 className="text-2xl font-bold mb-6 text-primary text-center">
                        Δημοτικές Υπηρεσίες
                    </h1>
                    {faqs.map((faq, index) => (
                        <AccordionItem
                            key={index}
                            active={active}
                            handleToggle={handleToggle}
                            faq={faq}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

export default HomeLoggedOut
