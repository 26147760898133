import PropTypes from 'prop-types'
import { PaymentFormTypes } from '../../enums/PaymentTypes'

export default function SelectFieldMobile({ name, text, options, onChange }) {
    return (
        <div className="flex w-full justify-between gap-3 border-b border-gray px-4 pb-1 pt-4 mr-5 flex-col md:flex-row">
            <div className="flex justify-between w-2/6">
                <p className="font-semibold">{text}</p>
            </div>
            <select
                name={name}
                className="md:self-end text-secondary flex-1 w-full md:w-2/3"
                onChange={onChange}
            >
                <option value="">Επιλέξτε</option>
                {options.map(option => (
                    <option
                        disabled={option.value === PaymentFormTypes.waterBill}
                        key={option.value}
                        value={option.value}
                    >
                        {option.text}
                    </option>
                ))}
            </select>
        </div>
    )
}

// Adding PropTypes
SelectFieldMobile.propTypes = {
    name: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            text: PropTypes.string.isRequired,
        })
    ).isRequired,
}
