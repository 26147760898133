import Modal from '../Modal/Modal'
import Header from '../Header/Header'
import AccordionItem from '../AccordionToggle/AccordionToggle'

import useMediaQuery from '../../hooks/useMediaQuery'
import { AuthContext } from '../../contexts/AuthContext'
import useProfileCompleted from '../../hooks/useProfileCompleted'

import { useState, useContext } from 'react'
import AsteriskCard from '../AsteriskCard/AsteriskCard'

const faqs = [
    {
        id: 1,
        accordionTitle:
            'Οδηγίες χρήσης τραπεζικών λογαριασμών και αιτιολογίες πληρωμών Govpay',
        accordionContent: (
            <div className="flex flex-col gap-2">
                <p>
                    Παρακαλούνται οι κυρίες & κύριοι που επιθυμούν να εξοφλήσουν
                    τις πάσης φύσεως οφειλές τους στο Δήμο Βύρωνα μέσω
                    ηλεκτρονικής πληρωμής(Alpha Web banking) να χρησιμοποιούν τα
                    κάτωθι ΙΒΑΝ.
                </p>
                <ol className="list-decimal ml-4">
                    <li>
                        Για οφειλές του Κοιμητηρίου (π.χ. Φύλαξη Οστών,
                        Οικογενειακούς Τάφους κλπ) στο λογαριασμό της Alpha Bank
                        με ΙΒΑΝ GR3201401720172002002010583
                    </li>
                    <li>
                        Για οφειλές ή Εγγραφές-Συνδρομές του Κολυμβητηρίου στο
                        λογαριασμό της Alpha Bank με ΙΒΑΝ
                        GR5401401720172002002010090
                    </li>
                    <li>
                        Για τις λοιπές πάσης φύσεως πληρωμές, που αφορούν το
                        Δήμο στο λογαριασμό της Alpha Bank με ΙΒΑΝ
                        GR9301401720172002002001046
                    </li>
                </ol>
                <p>
                    {`
                    Α) Στην Αιτιολογία πληκτρολογούμε την αιτία για την οποία
                    θέλουμε να πληρώσουμε π.χ. μηνιαία συνδρομή κολυμβητηρίου,
                    συνδρομή Ιανουαρίου 2018 για ζωγραφική, δικαίωμα ταφής για
                    τον Γ. (επώνυμο του θανόντος) Δ. (μικρό όνομα του θανόντος)
                    του Χ.(μικρό όνομα του πατέρα του θανόντος), Φύλαξη Οστών
                    για το έτος 2018(Ονοματεπώνυμο οφειλέτη/Αριθμός
                    κιβωτίου-30€),συμμετοχή στο χωριό παραγωγών Δεκεμβρίου 2017
                    κτλ.
                `}
                </p>
                <p className="font-bold">
                    Στην αιτιολογία βάζουμε επίσης τον κωδικό govpay Ειδικός
                    Κωδικός Αιτιολογίας Πληρωμής που θα βρούμε μέσα από το
                    σύστημα οικονομικής ενημέρωσης μετά από τη διαδικασία
                    αίτησης υπολοίπου για κάθε μια από τις παραπάνω περιπτώσεις{' '}
                </p>
                <p>{`Β) Στα Στοιχεία πληρωτή πληκτρολογούμε το μικρό όνομα, επώνυμο, μικρό όνομα του πατέρα του, ΑΦΜ & ΔΟΥ του φυσικού ή νομικού προσώπου που θέλει να πραγματοποιήσει την πληρωμή π.χ Αθανασάκης Κώστας του Νικηφόρου,ΑΦΜ:012345678 (τα 9 ψηφία του ΑΦΜ) & ΔΟΥ: ΒΥΡΩΝΑ, Μονοπρόσωπη ΕΠΕ, ΑΦΜ: 876543210 (τα 9 ψηφία του ΑΦΜ),ΔΟΥ ΙΓ' Αθηνών κτλ.`}</p>
                <p>{`Γ) Στη Πρόσθετη Πληροφορία πληκτρολογούμε επιπλέον ότι θεωρούμε ότι θα βοηθήσει περισσότερο στη ταυτοποίηση της πληρωμής.`}</p>
            </div>
        ),
    },
    {
        id: 2,
        accordionTitle: 'Εγχειρίδιο Χρήσης',
        accordionContent: (
            <>
                <p>
                    Μέσω της πλατφόρμα GovPay του Δήμου Βύρωνα έχετε τη
                    δυνατότητα να πληρώσετε μια οφειλή σας, να δείτε το ιστορικό
                    όλων των πληρωμών που έχετε πραγματοποιήσει και να αιτηθείτε
                    να μάθετε αν οφείλετε κάτι άλλο προς το Δήμο καθώς και να
                    ενημερωθείτε για όλους τους τρόπους επικοινωνίας και
                    υποστήριξης με στόχο την οικονομική ενημέρωση
                </p>
                <h4>Αίτηση Υπολοίπου</h4>
                <p>
                    Στη καρτέλα “Αίτηση Υπολοίπου” μπορείτε να ζητήσετε από το
                    Δήμο να σας ενημερώσει αν υπάρχει κάποια οφειλή προς
                    εξόφληση πατώντας το κουμπί “Αίτηση Υπολοίπου”. Καθ’ όλη τη
                    πορεία του αιτήματος σας θα ενημερώνεστε μέσω e-mail για την
                    εξέλιξή του. Μόλις η κατάσταση του αιτήματός σας
                    ολοκληρωθεί, τότε αν πατήσετε στο “Περισσότερα” που
                    βρίσκεται στο πίνακα του “Ιστορικό Αιτήσεων”, θα οδηγηθείτε
                    σε μία νέα σελίδα που θα έχει αναλυτικά τις οφειλές που
                    τυχόν υπάρχουν προς το Δήμο.
                </p>
                <p>
                    Αν υπάρχει κάποια οφειλή, τότε πατώντας το κουμπί “Πληρωμή”
                    οδηγείστε στο περιβάλλον της τράπεζας ώστε να ολοκληρώσετε
                    την πληρωμή σας
                </p>
                <h3>Ειδικός Κωδικός Αιτιολογίας Πληρωμής</h3>
                <p>
                    Ο ειδικός κωδικός χρησιμοποιείται όταν δεν έχει εκδοθεί
                    κωδικός RF ΔΙΑ για αυτη την συναλλαγή - Μπορείτε να
                    χρησιμοποιήσετε την ακόλουθη αιτιολογια μέσα από το e
                    banking της τράπεζας που χρησιμοποιείτε στο πεδίο αιτιολογία
                    140 χαρακτήρων ώστε να τακτοποιηθεί η πληρωμή σας από την
                    οικονομική υπηρεσία του δήμου ΛΟΓΑΡΙΑΣΜΟΣ:
                    GR9301401720172002002001046
                </p>
                <h4>Οι Οφειλές μου</h4>
                <p>
                    Από το μενού “Οι Οφειλές μου” μπορείτε να επιλέξετε τις
                    “Βεβαιωμένες Οφειλές, τις “Οφειλές σε Ρύθμιση” και το
                    “Ιστορικό Πληρωμών”.
                </p>
                <h4>Βεβαιωμένες Οφειλές</h4>
                <p>
                    Στον πίνακα που εμφανίζεται βλέπετε όλες τις βεβαιωμένες
                    οφειλές σας και μπορείτε να επιλέξετε μία ή και περισσότερες
                    για να πληρώσετε. Η επιλογή πρέπει να γίνεται από την
                    παλαιότερη στη πιο πρόσφατη οφειλή.
                </p>
                <p>
                    Αφού επιλέξετε τις οφειλές σας μπορείτε να πατήσετε το
                    κουμπί “Πληρωμή”, να οδηγηθείτε στο περιβάλλον της τράπεζας
                    και να πραγματοποιήσετε την πληρωμή.
                </p>
                <p>
                    Μόλις ολοκληρωθεί η πληρωμή, τότε θα εμφανιστεί στο
                    “Ιστορικό Πληρωμών”, όπου εκεί μπορεί να γίνει λήψη της
                    απόδειξης πληρωμής.
                </p>
                <h4>Οφειλές σε Ρύθμιση</h4>
                <p>
                    Στον πίνακα αυτόν εμφανίζονται όλες οι οφειλές που είναι σε
                    ρύθμιση. Μπορείτε να πληρώσετε μια ή και περισσότερες
                    οφειλές επιλέγοντάς τες από την πιο παλιά στην πιο πρόσφατη.
                </p>
                <p>
                    Στη συνέχεια, για να διεκπεραιωθεί η πληρωμή πρέπει να
                    πατήσετε το κουμπί <b>“Πληρωμή”</b> και να συνεχίσετε στο
                    περιβάλλον της τράπεζας.
                </p>
                <p>
                    Μετά την ολοκλήρωση της πληρωμής μπορείτε να τη δείτε στο
                    <b>“Ιστορικό Πληρωμών”</b>, όπου εκεί μπορεί να γίνει λήψη
                    της απόδειξης πληρωμής.
                </p>
                <h4>Ιστορικό Πληρωμών</h4>
                <p>
                    Για την πληρωμή οφειλής αυτό που χρειάζεται είναι να
                    μεταβείτε στη καρτέλα <b>“Πληρωμή Οφειλής”</b> και να
                    συμπληρώσετε όλα τα πεδία (Κωδικός Οφειλής, Αιτιολογία,
                    Κατηγορία Οφειλής και το ακριβές ποσό). Στη συνέχεια πατάτε
                    το κουμπί <b>“Πληρωμή”</b> και μεταφέρεστε στο περιβάλλον
                    της συνεργαζόμενης Τράπεζας για να διεκπεραιωθεί η πληρωμή
                    της οφειλής. Μόλις ολοκληρωθεί η διαδικασία θα αρχίσετε να
                    λαμβάνετε e-mails στο ηλεκτρονικό σας ταχυδρομείο σας που θα
                    σας ενημερώνουν για την εξέλιξη της πληρωμής σας μέχρι να
                    επιβεβαιωθεί και ολοκληρωθεί από το Δήμο.
                </p>
                <h4>Ιστορικό Πληρωμών</h4>
                <p>
                    Στη καρτέλα “Ιστορικό Πληρωμών” μπορείτε να δείτε όλες τις
                    πληρωμές οφειλών που έχετε πραγματοποιήσει και να
                    “κατεβάσετε” το αντίγραφο πληρωμής σας πατώντας το κουμπί
                    που βρίσκεται στη στήλη “Λήψη”. Πατώντας το κουμπί που
                    βρίσκεται στα “Περισσότερα” βλέπετε περισσότερες
                    λεπτομέρειες σχετικά την πληρωμή σας.
                </p>
                <h4>Ανανέωση Χρόνου</h4>
                <p>
                    H διάρκεια σύνδεσης στην πλατφόρμα έχει διάρκεια 15 λεπτά
                    για λόγους ασφαλείας. Αν επιθυμείτε τη μεγαλύτερη παραμονή
                    σας θα πρέπει να κάνετε ανανέωση στο κουμπί που βρίσκεται
                    δίπλα στο προφίλ σας.
                </p>
                <p>
                    Τα τελευταία 3 λεπτά πριν λήξουν η συνεδρία θα σας
                    εμφανιστεί μήνυμα, ώστε να προβείτε σε ανανέωση του χρόνου,
                    διαφορετικά μετά το πέρας των 15 λεπτών θα αποσυνδεθείτε.
                </p>
            </>
        ),
    },
    {
        id: 3,
        accordionTitle: 'Προϋποθέσεις Πληρωμής',
        accordionContent: (
            <ul className="list-disc">
                <li>
                    Είστε αποκλειστικά υπεύθυνοι για την ορθότητα των στοιχείων
                    που καταχωρείτε.
                </li>
                <li>
                    Η εξόφληση πρέπει να γίνεται για το ακριβές ποσό της
                    οφειλής.
                </li>
            </ul>
        ),
    },
]

const HomeLoggedIn = () => {
    const isMobile = useMediaQuery('(max-width:1024px)')
    const { token } = useContext(AuthContext)
    const profileCompleted = useProfileCompleted(token)

    const [active, setActive] = useState(null)

    const handleToggle = index => {
        if (active === index) {
            setActive(null)
        } else {
            setActive(index)
        }
    }
    return (
        <>
            {isMobile ? (
                <>
                    {!profileCompleted && (
                        <>
                            <AsteriskCard
                                htmlContent={`<div>Προκειμένου να έχετε πλήρη πρόσβαση στην πλατφόρμα, παρακαλούμε όπως συμπληρώσετε όλα τα επεξεργάσιμα πεδία του προφίλ <a class="font-bold" href="${'/profile'}"> εδώ </a></div>`}
                            />
                        </>
                    )}
                </>
            ) : null}
            <Modal classes="w-full max-w-7xl md:h-auto h-fit flex flex-col">
                {isMobile ? null : (
                    <Header
                        includeLogo={true}
                        withBorder={true}
                        includeButtons={true}
                    />
                )}

                <div className="w-full flex items-center flex-col py-8 md:py-20 px-7">
                    <div className="max-w-2xl py-10">
                        <h2 className="text-center font-bold text-2xl md:text-3xl mb-10">
                            Καλωσήρθατε στην Θυρίδα ηλεκτρονικών πληρωμών του
                            Δήμου Βύρωνα
                        </h2>
                        <p className="text-center text-secondary text-xl md:text-2xl">
                            Επιλέξτε στοιχεία από την αριστερή στήλη για να
                            δείτε τις οφειλές, τις πληρωμές σας, ή για να κάνετε
                            αίτημα εύκολα και με ελάχιστα κλικς.
                        </p>
                        {faqs.map((faq, index) => {
                            return (
                                <AccordionItem
                                    key={index}
                                    active={active}
                                    handleToggle={handleToggle}
                                    faq={faq}
                                />
                            )
                        })}
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default HomeLoggedIn
