import pdfMake from 'pdfmake/build/pdfmake'
import { formatDate, formatAmount, imgToBase64 } from './formatters'
import pngLogo from '../assets/images/logo.png'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import { commisionerNormal } from '../assets/fonts/pdfMakeFonts/CommissionerNormal'
import { commisionerBold } from '../assets/fonts/pdfMakeFonts/CommisionerBold'

pdfMake.vfs = pdfFonts.pdfMake.vfs
window.pdfMake.vfs['Commissioner-Regular.ttf'] = commisionerNormal
window.pdfMake.vfs['Commissioner-Bold.ttf'] = commisionerBold

export async function generatePDF(paymentData, profile) {
    const convertedImage = await imgToBase64(pngLogo)
    pdfMake.fonts = {
        Commissioner: {
            normal: 'Commissioner-Regular.ttf',
            bold: 'Commissioner-Bold.ttf',
        },
    }

    const docDefinition = {
        defaultStyle: {
            font: 'Commissioner',
        },
        header: [
            {
                image: convertedImage,
                width: 60,
                margin: [20, 15, 20, 30],
            },
        ],
        content: [
            {
                text: `Αρ.Απόδειξης: ${paymentData.systemCode}`,
                style: 'header',
                margin: [20, 15, 0, 10],
            },
            {
                text: `Ημερομηνία Δημιουργίας: ${formatDate(
                    paymentData.paymentDate
                )}`,
                style: 'mainText',
                margin: [0, 15],
            },
            {
                text: `Κωδικός πληρωμής: ${paymentData.paymentCode}`,
                style: 'mainText',
                margin: [0, 15],
            },
            {
                text: `Α.Φ.Μ. Οφειλέτη: ${profile.taxid}`,
                style: 'mainText',
                margin: [0, 15],
            },
            {
                text: `Ονοματεπώνυμο Οφειλέτη: ${profile.firstName} ${profile.lastName}`,
                style: 'mainText',
                margin: [0, 15],
            },
            {
                style: 'paymentTable',
                table: {
                    headerRows: 1,
                    body: [
                        [
                            { text: 'Αιτιολογία', style: 'tableHeader' },
                            { text: 'Ποσό', style: 'tableHeader' },
                        ],
                        [
                            `${paymentData.description}`,
                            `${formatAmount(paymentData.amount)}`,
                        ],
                    ],
                },
                layout: 'headerLineOnly',
            },
        ],
        footer: [
            {
                text: 'Δήμος Βύρωνα',
                margin: [0, 10],
                alignment: 'center',
            },
        ],
        styles: {
            header: {
                fontSize: 18,
                bold: true,
                alignment: 'center',
            },
            mainText: {
                fontSize: 10,
                alignment: 'left',
                bold: true,
                margin: [0, 0, 0, 35],
            },
            paymentTable: {
                margin: [0, 25, 0, 35],
            },
            tableHeader: {
                bold: true,
                fontSize: 10,
                color: 'black',
            },
            tableExample: {
                margin: [0, 5, 0, 35],
            },
        },
    }
    const pdfDocGenerator = pdfMake.createPdf(docDefinition)
    const filename = `GovPay receipt - ${paymentData.systemCode}.pdf`
    pdfDocGenerator.download(filename)
}
