import { useId } from 'react'
import PropTypes from 'prop-types'
import { PaymentFormTypes } from '../../enums/PaymentTypes'

const SelectField = ({
    name,
    label,
    options,
    labelOnSide = true,
    classes,
    onChange,
    value,
    required,
}) => {
    const id = useId()

    return (
        <>
            {labelOnSide ? (
                <label
                    className={`text-base text-right text-primary mb-14 flex justify-end gap-3 items-center ${
                        classes ?? ''
                    }`}
                    htmlFor={`${id}-${name}`}
                >
                    {label}
                    <div className="flex gap-3 w-2/3 border-b border-gray-300">
                        <select
                            id={`${id}-${name}`}
                            name={name}
                            className="w-full"
                            onChange={onChange}
                            value={value}
                            required={required}
                        >
                            <option value="">Επιλέξτε</option>
                            {options.map(option => (
                                <option
                                    disabled={
                                        option.value ===
                                        PaymentFormTypes.waterBill
                                    }
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </label>
            ) : (
                <label
                    className={`text-base text-left text-primary mb-12 ${
                        classes ?? ''
                    }`}
                    htmlFor={`${id}-${name}`}
                >
                    <div className="text-base font-bold">{label}</div>
                    <select
                        id={`${id}-${name}`}
                        name={name}
                        className="border-b border-gray-300 w-full mt-4"
                        onChange={onChange}
                        value={value}
                        required={required}
                    >
                        <option value="">Επιλέξτε</option>
                        {options.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </label>
            )}
        </>
    )
}

SelectField.propTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
        })
    ).isRequired,
    labelOnSide: PropTypes.bool,
    classes: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string,
    required: PropTypes.bool,
}

export default SelectField
