export default function GovpayDashboard() {
    return (
        <div className="p-6 bg-white min-h-screen flex flex-col items-center">
            {/* Header Section */}
            <div className="w-full bg-primary text-white text-center p-4 font-bold text-lg rounded-md">
                Υπηρεσία συγχρονισμού δεδομένων συστήματα οικονομικής
                διαχείρισης
            </div>

            {/* Main Info Section */}
            <div className="w-3/4 bg-blue-100 p-6 mt-4 text-center rounded-lg shadow-md">
                <p>
                    Μέσα από την υπηρεσία Govpay connect μπορείτε να ανεβάσετε
                    τις κινήσεις λογαριασμών από όλες τις συστημικές τράπεζες
                    που συνεργάζεται ο Δήμος ή τα νομικά πρόσωπα. Ενδεικτικά
                    αρχεία CSV, JSON, XML.
                </p>
                <p className="mt-4">
                    Στην περίπτωση που το σύστημα εντοπίσει πληρωμές με
                    αιτιολογία ΔΙΑ (RF) ή Ειδικό κωδικό πληρωμής Govpay τότε θα
                    επιβεβαιώσει αυτόματα την πληρωμή και το ποσό για το ΑΦΜ
                    χρήστη της υπηρεσίας πληρωμών.
                </p>
            </div>

            {/* Buttons */}
            <div className="flex gap-4 mt-6">
                <label className="bg-accent  text-white font-bold py-2 px-4 rounded shadow cursor-pointer">
                    Ανέβασμα Αρχείου
                    <input type="file" className="hidden" />
                </label>
                <button className="bg-accent  text-white font-bold py-2 px-4 rounded shadow">
                    Προβολή εκκαθάρισης
                </button>
            </div>

            {/* Reports Section */}
            <div className="w-3/4 bg-primary text-white text-center p-4 font-bold text-lg mt-6 rounded-lg shadow-md">
                ΑΝΑΦΟΡΕΣ
            </div>

            <div className="flex gap-4 mt-4">
                <button className="bg-accent  text-white font-bold py-2 px-4 rounded shadow">
                    Επιβεβαιωμένες πληρωμές περιόδου
                </button>
                <button className="bg-accent  text-white font-bold py-2 px-4 rounded shadow">
                    Αναφορά υπολοίπων
                </button>
            </div>

            {/* API Section */}
            <a
                href="/swagger"
                target="_blank"
                rel="norefferer"
                className="w-3/4 bg-primary text-white text-center p-4 font-bold text-lg mt-6 rounded-lg shadow-md"
            >
                API ΔΙΑΛΕΙΤΟΥΡΓΙΚΟΤΗΤΑΣ
            </a>
        </div>
    )
}
