import PropTypes from 'prop-types'
import Header from '../components/Header/Header'
import Modal from '../components/Modal/Modal'
import AccordionItem from '../components/AccordionToggle/AccordionToggle'
import useMediaQuery from '../hooks/useMediaQuery'
import { useState } from 'react'

const MunicipalServices = () => {
    const isMobile = useMediaQuery('(max-width:1024px)')
    const [active, setActive] = useState(null)

    const handleToggle = index => {
        if (active === index) {
            setActive(null)
        } else {
            setActive(index)
        }
    }

    const faqs = [
        {
            id: 1,
            accordionTitle: 'Ταμειακή υπηρεσία',
            accordionContent: (
                <div>
                    <p>Ταμειακή Υπηρεσία Τηλ.: 213 2008634, 635, 637, 638</p>
                    <p>E-mail: tameio@dimosbyrona.gr</p>
                    <p>
                        Ώρες Εξυπηρέτησης Κοινού: Δευτέρα έως Παρασκευή 08:00 -
                        13:30 (για συναλλαγές μόνο μέσω POS με χρεωστική ή
                        πιστωτική κάρτα){' '}
                    </p>
                    <p>
                        Το Τμήμα Ταμειακής Υπηρεσίας είναι υπεύθυνο για την
                        είσπραξη και την διαχείριση των εσόδων και των εξόδων
                        του Δήμου Βύρωνα.
                    </p>
                </div>
            ),
        },
        {
            id: 2,
            accordionTitle: 'Δημοτικό Κοιμητήριο',
            accordionContent: (
                <div>
                    <p>Τηλ: 210 7661188, 210 7645429</p>
                    <p>
                        Το Τμήμα Δημοτικού Κοιμητηρίου ασχολείται με τη
                        λειτουργία, τον καθαρισμό και τη συντήρηση του Δημοτικού
                        Κοιμητηρίου Βύρωνα. Επίσης, εισπράττει τα δικαιώματά του
                        από τους δημότες και χορηγεί βεβαιώσεις πληρωμών.
                    </p>
                </div>
            ),
        },
        {
            id: 3,
            accordionTitle: 'Έσοδα',
            accordionContent: (
                <div>
                    <p>Έσοδα Τηλ: 213 2008628, 629, 630</p>
                    <p>E-mail: esoda@dimosbyrona.gr</p>
                    <p>
                        Ώρες Εξυπηρέτησης Κοινού: Δευτέρα έως Παρασκευή: 08:00 -
                        13:00 (για πάσης φύσεως πληρωμές) Δευτέρα έως Παρασκευή:
                        08:00 - 14:00 (για πληροφορίες){' '}
                    </p>
                    <p>
                        {' '}
                        Το Τμήμα Εσόδων είναι υπεύθυνο για την έκδοση αδειών
                        άσκησης υπαιθρίων επαγγελμάτων και κατάληψης
                        κοινοχρήστων χώρων, καθώς και τον έλεγχο φορολογικών
                        στοιχείων και την επίλυση οικονομικών διαφορών. Στις
                        αρμοδιότητές του συγκαταλέγονται, επίσης, ο καθορισμός
                        του ύψους δικαιωμάτων, τελών και φόρων, η διαχείριση της
                        δημοτικής περιουσίας (μίσθωση, εκμίσθωση και εκποίηση
                        ακινήτων) και η είσπραξη των τελών ακίνητης περιουσίας
                        (ΤΑΠ).
                    </p>
                </div>
            ),
        },
    ]

    return (
        <>
            <Modal classes="w-full max-w-7xl flex flex-col h-fit md:h-auto mx-6 md:mx-6">
                {!isMobile && (
                    <Header
                        includeLogo={false}
                        withBorder={true}
                        includeButtons={true}
                    />
                )}
                <div className="flex flex-col md:justify-center items-center grow">
                    <div className="container mx-auto px-16 py-8">
                        <h1 className="text-3xl font-bold mb-6 text-accent">
                            Δημοτικές Υπηρεσίες
                        </h1>
                        {faqs.map((faq, index) => (
                            <AccordionItem
                                key={index}
                                active={active}
                                handleToggle={handleToggle}
                                faq={faq}
                            />
                        ))}
                    </div>
                </div>
            </Modal>
        </>
    )
}

const ServiceCard = ({ title, phone, email, hours, description, note }) => {
    return (
        <div className="bg-white border-accent border-2 rounded-lg overflow-hidden">
            <div className="bg-blue-600 text-accent px-4 py-4">
                <h2 className="text-xl font-semibold">{title}</h2>
            </div>
            <div className="px-4 py-6">
                {phone && (
                    <p className="mb-2">
                        <span className="font-semibold">Τηλ:</span> {phone}
                    </p>
                )}
                {email && (
                    <p className="mb-2">
                        <span className="font-semibold">E-mail:</span> {email}
                    </p>
                )}
                {hours && (
                    <div className="mb-2">
                        <span className="font-semibold">
                            Ώρες Εξυπηρέτησης Κοινού:
                        </span>
                        {Array.isArray(hours) ? (
                            <ul className="list-disc list-inside">
                                {hours.map((hour, index) => (
                                    <li key={index}>{hour}</li>
                                ))}
                            </ul>
                        ) : (
                            <p>{hours}</p>
                        )}
                    </div>
                )}
                {note && <p className="mb-2 italic text-sm">{note}</p>}
                <p className="mt-4">{description}</p>
            </div>
        </div>
    )
}

ServiceCard.propTypes = {
    title: PropTypes.string.isRequired,
    phone: PropTypes.string,
    email: PropTypes.string,
    hours: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    description: PropTypes.string.isRequired,
    note: PropTypes.string,
}

export default MunicipalServices
