import './index.css'

import ReactDOM from 'react-dom/client'
import React from 'react'

import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from '@tanstack/react-query'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'

import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute'

import Home from './routes/Home'
import Login from './routes/Login'
import Profile from './routes/Profile'
import Payment from './routes/Payment'
import PaymentSuccess from './routes/PaymentSuccess'
import PaymentHistory from './routes/PaymentHistory'
import BalanceRequest from './routes/BalanceRequest'
import AdminHome from './routes/admin/Home'
import AdminLogin from './routes/admin/Login'
import AdminPayment from './routes/admin/Payment'
import AdminPaymentEdit from './routes/admin/PaymentEdit'
import AdminBalanceRequest from './routes/admin/BalanceRequest'
import AdminBalanceRequestEdit from './routes/admin/BalanceRequestEdit'
import Page404 from './routes/Page404'
import AdminPage404 from './routes/admin/Page404'
import Users from './routes/admin/Users'
import Index from './routes/Index'
import Admin from './routes/admin/Admin'
import BalanceRequestDetails from './routes/BalanceRequestDetails'
import PaymentFailure from './routes/PaymentFailure'
import PaymentDetails from './routes/PaymentDetails'
import EmailVerification from './routes/EmailVerification'
import DebtsInstallments from './routes/DebtsInstallments'
import DebtsConfirmed from './routes/DebtsConfirmed'
import ResetAdminPassword from './routes/ResetAdminPassword'
import ResetAdminPasswordForm from './routes/ResetAdminPasswordForm'

import InstantPayment from './routes/InstantPayment'
import InstantPaymentSuccess from './routes/InstantPaymentSuccess'
import InstantPaymentFailure from './routes/InstantPaymentFailure'

import UnderConstruction from './routes/UnderConstruction'
import GovpayDashboard from './routes/admin/GovpayInfo'

import Roles from './enums/Roles'
import { AuthProvider } from './contexts/AuthContext'
import AdminInstantPayments from './routes/admin/InstantPayment'
import AdminInstantPaymentEdit from './routes/admin/InstantPaymentEdit'

import AdminProfile from './routes/admin/Profile'
import Register from './routes/Register'
import MunicipalServices from './routes/Contact'

const root = ReactDOM.createRoot(document.getElementById('root'))

const Router = () => {
    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetAdminPassword />} />

            <Route path="/instant-payment" element={<InstantPayment />} />
            <Route
                path="/instant-payment/success"
                element={<InstantPaymentSuccess />}
            />
            <Route
                path="/instant-payment/failure"
                element={<InstantPaymentFailure />}
            />

            <Route path="/register" element={<Register />} />
            <Route path="/under-construction" element={<UnderConstruction />} />

            {/* User nested routes */}
            <Route path="/" element={<Index />}>
                <Route index element={<Home />} />

                <Route
                    path="auth/mail-verify/:vToken"
                    element={<EmailVerification />}
                />

                <Route
                    path="profile"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <Profile />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="payment"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <Payment />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="payment/success"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <PaymentSuccess />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="contact-info"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <MunicipalServices />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="payment/failure"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <PaymentFailure />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="debts/confirmed"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <DebtsConfirmed />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="debts/installments"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <DebtsInstallments />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="payments"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <PaymentHistory />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="payments/:id"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <PaymentDetails />
                        </ProtectedRoute>
                    }
                />

                <Route
                    path="balance-requests"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <BalanceRequest />
                        </ProtectedRoute>
                    }
                />
                <Route
                    path="balance-requests/:id"
                    element={
                        <ProtectedRoute
                            allowedRoles={[Roles.citizen, Roles.company]}
                        >
                            <BalanceRequestDetails />
                        </ProtectedRoute>
                    }
                />

                <Route path="/*" element={<Page404 />} />
            </Route>

            <Route path="/admin/login" element={<AdminLogin />} />
            <Route
                path="/auth/password-reset/:vToken"
                element={<ResetAdminPasswordForm />}
            />

            {/* Admin nested routes */}
            <Route
                path="/admin"
                element={
                    <ProtectedRoute allowedRoles={[Roles.admin]}>
                        <Admin />
                    </ProtectedRoute>
                }
            >
                <Route index element={<AdminHome />} />
                <Route path="profile" element={<AdminProfile />} />
                <Route path="users" element={<Users />} />

                <Route
                    path="instant-payment"
                    element={<AdminInstantPayments />}
                />
                <Route
                    path="instant-payment/:id"
                    element={<AdminInstantPaymentEdit />}
                />

                <Route path="payment" element={<AdminPayment />} />
                <Route path="info" element={<GovpayDashboard />} />
                <Route path="payment/:id" element={<AdminPaymentEdit />} />

                <Route
                    path="balance-request"
                    element={<AdminBalanceRequest />}
                />
                <Route
                    path="balance-request/:id"
                    element={<AdminBalanceRequestEdit />}
                />
                <Route path="/admin/*" element={<AdminPage404 />} />
            </Route>
        </Routes>
    )
}

const queryClient = new QueryClient()

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                scriptProps={{
                    language: 'el',
                    appendTo: 'head',
                    async: false,
                    defer: false,
                }}
            >
                <AuthProvider>
                    <QueryClientProvider client={queryClient}>
                        <Router />
                    </QueryClientProvider>
                </AuthProvider>
            </GoogleReCaptchaProvider>
        </BrowserRouter>
    </React.StrictMode>
)
